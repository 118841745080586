// Custom Cursor
const cursor = document.querySelector('.cursor');
document.addEventListener('mousemove', (e) => {
    cursor.setAttribute("style", "top: "+(e.pageY)+"px; left: "+(e.pageX)+"px;")
})

const links = document.querySelectorAll('.sentence a');
links.forEach(link => {
    link.addEventListener('mouseover', () => {
        cursor.classList.add('link-grow');
    })
    link.addEventListener('mouseleave', () => {
        cursor.classList.remove('link-grow');
    })
})

const contactLinks = document.querySelectorAll('.contact_wrapper a');
contactLinks.forEach(link => {
    link.addEventListener('mouseover', () => {
        cursor.classList.add('contact-link-grow');
    })
    link.addEventListener('mouseleave', () => {
        cursor.classList.remove('contact-link-grow');
    })
})