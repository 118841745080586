import { gsap } from "gsap";
import { Timeline } from "gsap/gsap-core";

gsap.registerPlugin(Timeline);

// Mobile
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const tlIntro = gsap.timeline();
const tlIntro2 = gsap.timeline({ delay: 2.8 });
const tlIntro3 = gsap.timeline({ delay: 3.8 });
const tlIntro4 = gsap.timeline({ delay: 3.8 });
const tlIntro5 = gsap.timeline({ delay: 4.3 });

tlIntro
    // .to('.preloader', {duration: 1, scale: 0, borderRadius: '+=100%', ease: 'power3.inOut'})
    .from('.preloader h1', { duration: .7, yPercent: 50, autoAlpha: 0 })
    .to('.preloader h1', { duration: .7, yPercent: 0, autoAlpha: 1 })

tlIntro2
    // .from('.preloader', {duration: .7, y: , autoAlpha:0})
    .to('.preloader', { duration: 1.3, y: '-100%', ease: 'expo.inOut' })

tlIntro3
    .from('.header_content', { duration: 1, y: -50, autoAlpha: 0 })
    .to('.header_content', { duration: 1, y: 0, autoAlpha: 1 })

tlIntro4
    .from('.footer_content', { duration: 1, y: 50, autoAlpha: 0 })
    .to('.footer_content', { duration: 1, y: 0, autoAlpha: 1 })

tlIntro5
    .from('.sentences', { duration: 1, autoAlpha: 0 })
    .to('.sentences', { duration: 1, autoAlpha: 1 })

let sliderTween = function () {

    let slide_tl1 = new Timeline({ repeat: -1 });

    slide_tl1

        .to('.slidetext1', .7, { yPercent: -50, autoAlpha: 0, delay: 2 })
        .from('.slidetext2', .7, { yPercent: 50, autoAlpha: 0 })//red slider bar underneath text

        .to('.slidetext2', .7, { yPercent: -50, autoAlpha: 0, delay: 2 })
        // Make the first text jump to the start point
        .set('.slidetext1', { yPercent: 50 })
        .to('.slidetext1', .7, { yPercent: 0, autoAlpha: 1 });

};

let sliderTweenMobile = function () {

    let slide_tl1 = new Timeline({ repeat: -1 });

    slide_tl1

        .to('.slidetext1', .7, { autoAlpha: 0, delay: 2 })
        .from('.slidetext2', .7, { autoAlpha: 0 })//red slider bar underneath text

        .to('.slidetext2', .7, { autoAlpha: 0, delay: 2 })
        // Make the first text jump to the start point
        .to('.slidetext1', .7, { autoAlpha: 1 });

};
if (isMobile) {
    sliderTweenMobile();
} else {
    sliderTween();
}

const toggleMenu = document.querySelector('.mobile-nav-button');
const name = document.querySelector('.name');
const root = document.querySelector(':root');
const tl = gsap.timeline({ reversed: true });

tl
    .from('.mobile-nav', {
        duration: 0.6,
        ease: 'power3.inOut',
        left: '-100%',
    })
    .from('.mobile-nav li', {
        duration: 0.3,
        opacity: 0,
        x: -20,
        ease: 'power3.inOut',
        stagger: 0.05,
    })
    .from('.mobile-nav p', {
        duration: 0.3,
        opacity: 0,
        y: 20,
        ease: 'power3.inOut',
    });

function animateIt() {
    tl.reversed() ? tl.play() : tl.reverse();

    if (!tl.reversed()) {
        name.classList.add('blue');
        toggleMenu.classList.add('active');
        toggleMenu.classList.add('bg-blue');
        root.style.setProperty('--delay', '.4s');
        root.style.setProperty('--delay-name', '0s');
    } else {
        name.classList.remove('blue');
        toggleMenu.classList.remove('active');
        toggleMenu.classList.remove('bg-blue');
        root.style.setProperty('--delay', '.7s');
        root.style.setProperty('--delay-name', '1s');
    }
}

toggleMenu.addEventListener('click', animateIt);
tl.pause(0).reversed(true)